import { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@trackman/web-shared-components';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const ParameterMainImage = observer(() => {
  const ps = useStores().parametersStore;
  const imgInputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    ps.isSaving && setSelectedFile(undefined);
  }, [ps.isSaving]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(ps.parameter.mainImgData?.data.name || '');
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, ps.parameter.mainImgData]);

  const onSelectFile = useCallback(
    (e) => {
      if (!imgInputRef.current?.files?.length) {
        setSelectedFile(undefined);
      } else {
        setSelectedFile(imgInputRef.current?.files[0]);
        ps.isMainImgChanged = true;
        ps.parameter.mainImgData = {
          id: '',
          data: imgInputRef.current?.files[0],
        };
      }
    },
    [ps]
  );

  return (
    <div className='main-image-container'>
      <Paragraph>
        <>Main parameter image</>
      </Paragraph>
      <div className='inputs-container'>
        {preview ? (
          <img src={preview} alt={selectedFile?.name} />
        ) : ps.parameter.mainImgUrl ? (
          <img src={ps.parameter.mainImgUrl} alt={ps.parameter.mainImgUrl} />
        ) : (
          <></>
        )}
        <input type='file' id='file' accept='image/*' ref={imgInputRef} style={{ display: 'none' }} onChange={onSelectFile} />
        <Button
          className='styled-button secondary'
          label={ps.parameter.mainImgData?.data.name ? 'Change image' : 'Upload image'}
          intent='secondary'
          onClick={() => imgInputRef.current?.click()}
          placeholder={undefined}
        />
      </div>
    </div>
  );
});

export default ParameterMainImage;
