import './AdminWYSIWYGEditor.scss';
import '../../../pages/Admin/Admin.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { useEffect, useState } from 'react';

import { AdminItemDescriptionType } from 'types/admin/courses';
import { Editor } from 'react-draft-wysiwyg';
import { LanguageType } from 'types/languages';
import Paragraph from 'components/TextComps/Paragraph';
import draftToHtml from 'draftjs-to-html';
import { getLanguageText } from 'utilities/languages';
import htmlToDraft from 'html-to-draftjs';
import { observer } from 'mobx-react-lite';

type Props = {
  text: string | null;
  dataItems: AdminItemDescriptionType[];
  language: LanguageType;
};

export const AdminWYSIWYGEditor = observer(({ text, dataItems, language }: Props) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [contentToSave, setContentToSave] = useState('');

  useEffect(() => {
    if (text === null) return;
    const contentBlock = htmlToDraft(text);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [text]);

  return (
    <div className='text-editor-container'>
      <Paragraph>
        <>{getLanguageText(language)}</>
      </Paragraph>
      <Editor
        editorState={editorState}
        toolbarClassName='question-editor-toolbar'
        wrapperClassName='question-editor-wrapper'
        editorClassName='question-editor'
        onEditorStateChange={(editorState) => setEditorState(editorState)}
        onChange={() => {
          const convertedContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
          const cleanedContent = convertedContent === '<p></p>\n' || convertedContent === '<p></p>' ? '' : convertedContent;

          setContentToSave(cleanedContent);
        }}
      />
      <button
        className='styled-button secondary'
        onClick={() => {
          dataItems.find((desc) => {
            if (desc.countryCode === language) {
              desc.text = contentToSave;
            }
            return desc;
          });
        }}
      >
        Save editor changes
      </button>
    </div>
  );
});

export default AdminWYSIWYGEditor;
